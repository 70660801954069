<template>

    <div v-if="dataSource" class="p-relative">

        <Toolbar :isList="true" :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick">

            <div class="top-btns-bar-right-section">
                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="category"
            v-bind:substatus.sync="status"
        >
        </FilterPanel>

        <v-row no-gutters>

            <v-col cols="12" xs="12" md="12">
                <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="entities"
                    :options.sync="options"
                    :server-items-length="total"
                    :items-per-page="10"
                    :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                    :page.sync="options.page"
                    @page-count="paginationPageCount = $event"
                    class="elevation-1 customization-list-table pagination-list-table sticky-table"
                    @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Documents.NPA.Document', common: true })"
                    >

                    <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                    <template v-slot:[`item.regdate`]="{ item }">
                        <div>{{dateFormat(item.regdate, 'DD.MM.YYYY')}}</div>
                    </template>
                    
                    <template v-slot:[`item.theme`]="{ item }">
                        <div class="elipsis-text-wrap">                        
                            <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>
                            <div class="etw-text-container">
                                <div class="etw-text" v-tooltip.top-center="item.theme">
                                    {{item.theme}}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`footer.prepend`]>
                        <v-pagination
                            v-model="options.page"
                            :length="paginationPageCount"
                            :total-visible="$listPaginationTotal"
                            color="teal"
                        ></v-pagination>
                    </template>

                </v-data-table>
            </v-col>

        </v-row>

    </div>

</template>

<script>
import Axios from 'axios';
import i18n from '@/i18n'
import { httpAPI } from "@/api/httpAPI";
import { mapActions } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import FilterPanel from '@/components/FilterPanel.vue';
import _ from 'lodash';
import sys from '@/services/system';

export default {
    name: "NPALists",
    columnIndex: {
        'regnumber': 6,
        'regdate': 7,        
        'theme': 4,        
    },
    components: {
        Toolbar,
        FilterPanel,
    },
    data() {
        return {
            cancellationTokenSorce: null,
            loading: true,
            total: 0,
            entities: [],
            dataSource: null,
            filterDataSource: null,
            paginationPageCount: 0,
            headers: [
                { text: i18n.t("Рег._№"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: i18n.t("Краткое_содержание"), value: 'theme'},
            ],
        }
    },
    computed : {
        options: {
            get: function() {
                return this.$store.getters['npa/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('npa/SET_TABLE_OPTION', newValue);
            }
        },
        category: {
            get: function() {
                return this.$store.getters['npa/GetCategory']
            },
            set: function(newValue) {
                this.$store.commit('npa/SET_CATEGORY', newValue);
                this.$store.commit('npa/SET_TABLE_OPTION_PAGE', 1);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['npa/GetStatus'];
            },
            set: function(newValue) {
                this.$store.commit('npa/SET_STATUS', newValue);
            }
        },
        search: {
            get: function() {
                return this.$store.getters['npa/GetTableSearch'];
            },
            set: _.debounce(function(v) {
                this.$store.commit('npa/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('npa/SET_TABLE_SEARCH', v);
            }, 500)
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                NPAYear: this.status,
                NPAType: this.category
            }
        },
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        onToolbarClick(event, btn) {
            this[btn.Action](btn);
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
            
            this.setOverlayVisible({ visible: true });    

            this.prevTableFilterObject = this.getTableFilterObject();

            this.total = 0;
            this.projects = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();            

            let response = await httpAPI({
                url: `api/npa/list?filter=${JSON.stringify(this.prevTableFilterObject)}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;
            
            this.loading = false;
            this.setOverlayVisible({ visible: false });

        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, NPAYear, NPAType } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 4;
            return { SearchStr, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage, NPAYear, NPAType, RegState: 1 };
        },
        async Refresh() {
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();
            
            let filterResponse = await httpAPI({
                url: `api/npa/filter?category=${this.category}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });
            
            if (filterResponse)
                this.filterDataSource = filterResponse.data.payload;

            await this.getData(true);
        },
        dateFormat(value, format, inputFormat)
        {
            return sys.dateFormat(value, format, inputFormat);
        },
    },
    watch: {
        filter: {
            handler () {
                if (this.prevTableFilterObject.NPAType != this.filter.NPAType)
                    this.Refresh();
                else 
                    this.getData()
            },
            deep: true,
        },
    },
    async created() {
        this.setOverlayVisible({ visible: true });

        if (this.cancellationTokenSorce)
            this.cancellationTokenSorce.cancel('New request started');

        this.cancellationTokenSorce = Axios.CancelToken.source();

        let response = await httpAPI({
            url: `api/actions/collection?type=Documents.NPAApproved`,
            method: 'GET',
            cancelToken: this.cancellationTokenSorce.token,
        });
        if (response) {
            await this.Refresh();
            this.dataSource = response.data.payload;
        }    

        this.setOverlayVisible({ visible: false });
    },
}
</script>
